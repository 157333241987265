<template>
  <div style="width:200px;">
    <v-menu
      v-model="menu"
      :nudge-right="40"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dates"
          :label="fieldLabel"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="dates"
        @input="menu = false"
        @change="changedDate"
      ></v-date-picker>
    </v-menu>
  </div>
</template>
<script>
export default {
  name: "CalenderDialog",
  props: ["defaultDate", "fieldLabel", "controller"],
  methods: {
    changedDate(val) {
      //console.log("date changed"+val);
      this.$emit("callback", val);
    },
    updateDateInCalendar(date) {
      this.dates = date;
    },
  },
  beforeMount() {
    this.dates = this.defaultDate;
    this.controller.updateDateInCalendar = this.updateDateInCalendar;
  },
  data: () => ({
    dates: null,
    menu: false,
  }),
};
</script>
